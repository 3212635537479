import type {
  ActionFunctionArgs,
  LoaderFunctionArgs,
  MetaFunction
} from '@remix-run/node';

import { Form, Link, useLoaderData } from '@remix-run/react';
import { TriangleAlert } from 'lucide-react';
import { useEffect, useState } from 'react';
import { Alert, AlertDescription, AlertTitle } from '~/components/ui/alert';
import { Button } from '~/components/ui/button';
import { Boundary } from '~/components/ui/error';
import { Input } from '~/components/ui/input';
import { Label } from '~/components/ui/label';
import { getUserByEmail } from '~/models/user/get-user';
import { requireNotAuthenticated } from '~/services/auth/config.server';
import { commitSession, getSession } from '~/services/auth/session.server';
import { sendOtpToUser } from '~/services/auth/totp.server';
import { redirect } from '~/utils/redirect.server';
import { useFormState } from '~/utils/use-form-state';

export const meta: MetaFunction = () => [
  { title: 'tia - Login' },
  {
    description: 'Login to tia'
  }
];

export const ErrorBoundary = Boundary;

export async function loader({ request, response }: LoaderFunctionArgs) {
  await requireNotAuthenticated(request, response);

  const session = await getSession(request.headers.get('Cookie'));

  const email = session.get('email') as string | undefined;
  const error = session.get('authError') as string | undefined;

  response?.headers.append('Set-Cookie', await commitSession(session));

  return { email, error };
}

export async function action({ request, response }: ActionFunctionArgs) {
  const formData = await request.formData();
  const email = formData.get('email');
  const timezoneOffset = formData.get('timezoneOffset');
  const session = await getSession(request.headers.get('Cookie'));

  if (typeof email !== 'string' || !email.length) {
    session.flash('authError', 'Email is required.');
    throw redirect('/login', 302, {
      'Set-Cookie': await commitSession(session)
    });
  }

  const existingUser = await getUserByEmail(email);

  if (!existingUser) {
    session.set('email', email);
    session.flash('authError', 'Invalid email.');
    throw redirect('/login', 302, {
      'Set-Cookie': await commitSession(session)
    });
  }

  const otp = await sendOtpToUser(
    'login',
    existingUser.name || undefined,
    existingUser.email
  );
  session.set('otp', otp.id);
  session.set('email', existingUser.email);
  if (typeof timezoneOffset === 'string' && timezoneOffset.length > 0) {
    session.set('timezoneOffset', timezoneOffset);
  }

  return redirect('/login/confirm', 302, {
    'Set-Cookie': await commitSession(session, { maxAge: 60 * 10 })
  });
}

export default function Login() {
  let loaderData = useLoaderData<typeof loader>();
  const email = loaderData?.email || '';
  let error = loaderData.error as React.ReactNode | string | undefined;
  const formState = useFormState();

  // remove the chat id from the session storage at login time
  // to avoid mixing chats between users
  useEffect(() => {
    sessionStorage.removeItem('atlas-chat-id');
  }, []);

  const [timezoneOffset, setTimezoneOffset] = useState(0);

  useEffect(() => {
    setTimezoneOffset(new Date().getTimezoneOffset());
  }, []);

  if (error) {
    error =
      error === 'Invalid email.' ? (
        <>
          Invalid email. Would you like to{' '}
          <Link to="/register" className="underline">
            register for an account
          </Link>
          ?
        </>
      ) : (
        error
      );
  }

  return (
    <>
      {error && (
        <Alert variant="destructive">
          <TriangleAlert className="h-4 w-4" />
          <AlertTitle>Error</AlertTitle>
          <AlertDescription>
            {error || 'An error has occurred. Please try again.'}
          </AlertDescription>
        </Alert>
      )}

      <div className="flex flex-col space-y-2 text-center">
        <h1 className="text-2xl font-semibold tracking-tight">Log into your account</h1>
        <p className="text-sm text-muted-foreground">
          Enter your email below to log into your account. We'll send you a code to verify
          your identity.
        </p>
      </div>

      <Form method="post" className="w-full">
        <fieldset className="space-y-2" disabled={formState.submitting}>
          <input type="hidden" name="timezoneOffset" value={timezoneOffset} />

          <div>
            <Label htmlFor="email">Email Address</Label>
            <Input
              name="email"
              id="email"
              type="email"
              placeholder="john.doe@acme.com"
              defaultValue={email}
              autoFocus
              required
            />
          </div>
          <Button className="mt-4 w-full" type="submit">
            {formState.submitting ? 'Sending Code…' : 'Send Code'}
          </Button>
        </fieldset>
      </Form>

      <div className="relative my-3">
        <div className="absolute inset-0 flex items-center">
          <span className="w-full border-t" />
        </div>
        <div className="relative flex justify-center text-xs">
          <span className="bg-white px-2 text-muted-foreground">
            No Account Yet?{' '}
            <Link to="/register" className="underline">
              Register
            </Link>
          </span>
        </div>
      </div>
    </>
  );
}
